import { render, staticRenderFns } from "./ProjectsGridRow.vue?vue&type=template&id=f1662528&scoped=true&"
import script from "./ProjectsGridRow.vue?vue&type=script&lang=js&"
export * from "./ProjectsGridRow.vue?vue&type=script&lang=js&"
import style0 from "./ProjectsGridRow.vue?vue&type=style&index=0&id=f1662528&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1662528",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsivePicture: require('/codebuild/output/src593722035/src/lusio/components/global/ResponsivePicture.vue').default})
